<template>
    <router-view />
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
    font-size: 14px;
}
* {
    scrollbar-width: none;
    font-family: "Poppins", sans-serif;
}
html {
    padding: 0;
    margin: 0;
}
body {
    width: 100vw;
    padding: 0;
    margin: 0;
}
</style>
