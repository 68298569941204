<template>
    <div class="navbar">
        <div class="logo-container">
            <img src="@/assets/logoipsum-293.svg" width="75" height="75" />
        </div>
        <DxTreeView
            :data-source="navbarItems"
            :select-by-click="true"
            :onItemClick="selectedItem"
            selection-mode="single"
            class="navbar-tree-view"
        />
        <div class="user">
            <img src="@/assets/person.svg" class="user-icon" alt="" />
            <span class="user-name"> admin </span>
        </div>
        <div class="logout">
            <DxButton
                :width="100"
                text="ODJAVA"
                type="danger"
                styling-mode="contained"
                @click="logout"
            />
        </div>
    </div>
</template>
<script>
import { navBarItems } from "../../navBarItems.js";
import DxTreeView from "devextreme-vue/tree-view";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import eventBus from "../../eventBus.js";
export default {
    name: "navBarComponent",
    components: {
        DxTreeView,
        DxButton,
    },
    data() {
        return {
            navbarItems: navBarItems,
        };
    },
    methods: {
        logout() {
            const message = "Logged out.";
            notify(
                {
                    message,
                    width: 450,
                },
                "success",
                2000
            );
            localStorage.removeItem("userId");
            localStorage.removeItem("userDomainId");
            this.$router.push("/login");
        },
        selectedItem(e) {
            if (e.node.children.length === 0)
                eventBus.emit("tableToRender", e.node.text);
        },
    },
};
</script>
<style>
.logo-container {
    margin: 1vh auto 0 auto;
}
.user-icon {
    width: 30px;
}
.user-name {
    color: #1c1c1c;
    font-size: 1.4rem;
}
.user {
    margin: 0 1vw 1vh 1vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.logout {
    margin: 0 auto 1vw auto;
}
.navbar {
    position: fixed;
    width: 15vw;
    min-width: 15vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.navbar-tree-view {
    margin-top: 6vh;
    margin-bottom: 15vh;
    font-size: 1.2rem;
    color: #1c1c1c;
}
</style>
