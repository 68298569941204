import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const priceList = new CustomStore({
    key: "id",
    load: () => sendRequest(`${config.API_URL}/${config.PATHS.PRICE_LIST}`),
    insert: (values) => {
        sendRequest(`${config.API_URL}/${config.PATHS.PRICE_LIST}`, "POST", {
            values: values,
        });
    },
    byKey: (key) => {
        sendRequest(`${config.API_URL}/${config.PATHS.PRICE_LIST}/${key}`);
    },
    update: (id, values) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.PRICE_LIST}/${id}`,
            "PATCH",
            {
                values: values,
            }
        );
    },
    remove: (id) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.PRICE_LIST}/${id}`,
            "DELETE"
        );
    },
});
