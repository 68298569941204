<template>
    <div class="table-container">
        <span class="table-name">Cijenik</span>
        <DxDataGrid
            id="priceListTable"
            :data-source="priceList"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getPriceListTableInstance"
            @init-new-row="onInitNewRowPriceList"
            @focused-row-changed="onFocusedRowChanged"
            @row-updating="onRowUpdating"
            @edit-canceled="onEditCanceled"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="priceListTableState"
            />
            <DxToolbar>
                <DxItem
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing :allow-updating="true" mode="row" :use-icons="true">
                <DxPopup
                    :show-title="true"
                    title="Dodaj novi cijenik"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="2" :col-span="1" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="name" data-type="string" />
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :allow-editing="false"
                :visible="false"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Naziv"
                :allow-hiding="false"
            >
                <DxRequiredRule />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
            /></DxColumn>
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-editing="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-editing="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="edit"
                    @click="editPriceList"
                    :visible="areEditAndDeleteIconsVisible"
                />
                <!-- <DxButton
                    hint="Obriši"
                    icon="trash"
                    @click="deletePriceList"
                    :visible="areEditAndDeleteIconsVisible"
                /> -->
                <DxButton
                    hint="Spremi"
                    icon="check"
                    @click="savePriceList"
                    :visible="areSaveAndCancelIconsVisible"
                />
                <DxButton
                    hint="Odustani"
                    icon="close"
                    @click="cancel"
                    :visible="areSaveAndCancelIconsVisible"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxLookup,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxRequiredRule,
    DxPager,
    DxPaging,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { priceList } from "@/stores/priceListStore";
import { domain } from "@/stores/domainStore";
import { loggedUser } from "../../user.js";
import eventBus from "../../eventBus.js";

export default {
    name: "priceListTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxLookup,
        DxHeaderFilter,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxRequiredRule,
        DxPager,
        DxPaging,
    },
    data() {
        return {
            selectedRowKey: null,
            priceListTableInstance: null,
            createdById: null,
            modifiedById: null,
            createdDate: null,
            modifiedDate: null,
            administrator: loggedUser,
            priceList: priceList,
            domain: domain,
            columnChooserMode: "select",
            addButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addNewPriceList();
                },
            },
            refreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
        };
    },
    created() {
        this.adminId = localStorage.getItem("userId");
    },
    methods: {
        getPriceListTableInstance(e) {
            this.priceListTableInstance = e.component;
        },
        onInitNewRowPriceList(e) {
            const now = new Date();
            this.priceListTableInstance.option("focusedRowIndex", -1);
            const domainId = localStorage.getItem("userDomainId");
            e.data = {
                ...e.data,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(domainId),
            };
        },
        addNewPriceList() {
            this.priceListTableInstance.addRow();
        },
        savePriceList() {
            this.priceListTableInstance.saveEditData();
        },
        onRowUpdating(e) {
            const now = new Date();
            e.newData = {
                ...e.newData,
                modifiedById: Number(this.adminId),
                modifiedDate: now.toISOString(),
            };
        },
        cancel() {
            this.priceListTableInstance.cancelEditData();
        },
        onEditCanceled() {
            this.priceListTableInstance.refresh();
        },
        editPriceList(e) {
            this.priceListTableInstance.editRow(e.row.rowIndex);
        },
        deletePriceList(e) {
            this.priceListTableInstance.deleteRow(e.row.rowIndex);
        },
        onFocusedRowChanged(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                this.selectedRowKey = focusedRowKey;
                if (this.selectedRowKey !== null) {
                    eventBus.emit(
                        "newFocusedRowPriceList",
                        this.selectedRowKey
                    );
                }
            } else {
                eventBus.emit("newFocusedRowPriceList", null);
            }
        },
        refreshData() {
            const message = "Podaci osvježeni!";
            this.priceListTableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
        areEditAndDeleteIconsVisible(e) {
            return !e.row.isEditing ? true : false;
        },
        areSaveAndCancelIconsVisible(e) {
            return e.row.isEditing ? true : false;
        },
        openColumnChooser() {
            this.priceListTableInstance.showColumnChooser();
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#priceListTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
