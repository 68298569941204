import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let userDomainId = Number(localStorage.getItem("userDomainId"));
eventBus.on("newUserLoggedIn", () => {
    userDomainId = Number(localStorage.getItem("userDomainId"));
});

export const dispatchNote = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            if (userDomainId) {
                let filterCondition = {
                    where: {
                        domainId: userDomainId,
                    },
                };
                if (loadOptions.businessYearId || loadOptions.warehouseId) {
                    filterCondition = {
                        where: {
                            and: [
                                { domainId: userDomainId },
                                { businessYearId: loadOptions.businessYearId },
                                { warehouseId: loadOptions.warehouseId },
                            ],
                        },
                        order: "number DESC",
                        limit: 1,
                    };
                }
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.DISPATCH_NOTE
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    },
    byKey: async (key) => {
        const res = await sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${key}`
        );
        return res;
    },
    insert: async (values) => {
        try {
            const response = await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}`,
                "POST",
                {
                    values: values,
                }
            );
            return response;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: (id) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${id}`,
            "DELETE"
        );
    },
});
