import CustomStore from "devextreme/data/custom_store";
// import DataSource from "devextreme/data/data_source";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
// import eventBus from "../../eventBus";

export const businessYear = new CustomStore({
    key: "id",
    load: async () => {
        const res = await sendRequest(
            `${config.API_URL}/${config.PATHS.BUSINESS_YEAR}`
        );
        return res;
    },
    byKey: async (key) => {
        const res = await sendRequest(
            `${config.API_URL}/${config.PATHS.BUSINESS_YEAR}/${key}`
        );
        return res;
    },
});
