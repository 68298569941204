<template>
    <div class="heading">
        <h1 style="font-weight: 500">Sign In</h1>
    </div>
    <form
        @submit.prevent="signIn"
        style="display: flex; justify-content: center; align-items: center"
    >
        <DxForm
            :data-source="user"
            @field-data-changed="onFieldDataChanged"
            label-location="top"
            style="width: 20vw"
        >
            <DxSimpleItem data-field="email" :required="true">
                <DxEmailRule />
                <DxRequiredRule />
            </DxSimpleItem>
            <DxSimpleItem
                data-field="password"
                :editor-options="passwordEditorOptions"
            >
                <DxRequiredRule />
            </DxSimpleItem>
            <DxEmptyItem />
            <DxEmptyItem />
            <DxButtonItem
                :button-options="submitButtonOptions"
                styling-mode="contained"
            />
        </DxForm>
    </form>
</template>

<script>
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmailRule,
    DxRequiredRule,
    DxEmptyItem,
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";
import { loggedUser } from "../../user";
import { domain } from "../../domain";
import eventBus from "../../eventBus";

export default {
    name: "loginForm",
    data() {
        return {
            userInput: {
                email: "",
                password: "",
            },
            user: loggedUser,
            domain: domain,
            submitButtonOptions: null,
            passwordEditorOptions: null,
        };
    },
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmailRule,
        DxRequiredRule,
        DxEmptyItem,
    },
    created() {
        this.submitButtonOptions = {
            text: "Sign In",
            useSubmitBehavior: true,
            width: "100%",
            alignment: "left",
            type: "default",
        };
        this.passwordEditorOptions = {
            mode: "password",
        };
    },
    methods: {
        onFieldDataChanged(e) {
            this.userInput[e.dataField] = e.value;
        },
        signIn() {
            let userFound = false;
            let domainActive = false;
            const userName = this.userInput.email.split("@")[0];
            const userDomain = this.userInput.email.split("@")[1].split(".")[0];
            for (const key of this.user) {
                if (key.username === userName) {
                    for (const dom of domain) {
                        if (
                            dom.name === userDomain &&
                            dom.id === key.domainId &&
                            dom.status === "Active"
                        ) {
                            localStorage.setItem("userId", key.id);
                            localStorage.setItem("userDomainId", key.domainId);
                            localStorage.setItem("businessYearId", 13);
                            eventBus.emit("newUserLoggedIn");
                            userFound = true;
                            domainActive = true;
                        } else if (
                            dom.name === userDomain &&
                            dom.id === key.domainId &&
                            dom.status === "Inactive"
                        ) {
                            userFound = true;
                        }
                    }
                }
            }
            if (userFound && domainActive) {
                this.$router.push("/home");
            } else if (!userFound) {
                const message = "User not found!";
                notify(
                    {
                        message,
                        width: 450,
                    },
                    "error",
                    2000
                );
            } else if (!domainActive) {
                const message = "Domain inactive!";
                notify(
                    {
                        message,
                        width: 450,
                    },
                    "error",
                    2000
                );
            }
        },
        updateEmail(value) {
            this.email = value;
        },
    },
};
</script>

<style scoped>
.heading {
    width: 25vw;
    margin: 20vh auto 0 auto;
    text-align: center;
}
</style>
