import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let filterId = null;
eventBus.on("newFocusedRowDispatchNote", (id) => {
    filterId = id;
});

export const dispatchNoteItem = new CustomStore({
    key: "id",
    load: async () => {
        try {
            if (filterId !== null) {
                const filterCondition = {
                    where: {
                        dispatchNoteId: filterId,
                    },
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.DISPATCH_NOTE_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                eventBus.emit("dispatchNoteItems", newData);
                return newData;
            } else {
                eventBus.emit("dispatchNoteItems", []);
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    },
    byKey: (key) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${key}`
        );
    },
    update: (id, values) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${id}`,
            "PATCH",
            {
                values: values,
            }
        );
    },
    insert: (values) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}`,
            "POST",
            {
                values: values,
            }
        );
    },
    remove: (id) =>
        sendRequest(
            `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${id}`,
            "DELETE"
        ),
});
