import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const user = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        // console.log(loadOptions);
        try {
            const filterCondition = {};
            if (loadOptions.skip || loadOptions.take) {
                filterCondition.limit = loadOptions.take + 1;
                filterCondition.skip = loadOptions.skip;
            }
            if (
                loadOptions.searchValue !== null &&
                loadOptions.searchValue !== ""
            ) {
                filterCondition.where = {
                    or: [
                        {
                            sign: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            name: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            lastname: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            id: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                    ],
                };
            } else {
                if (filterCondition.where) {
                    delete filterCondition.where;
                }
            }
            let newData = await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}?filter=${JSON.stringify(
                    filterCondition
                )}`
            );
            // Filter za izabciti user sa id 0 iz popisa
            newData = newData.filter((el) => {
                return el.id !== 0;
            });
            return newData;
        } catch (error) {
            console.error("Error loading data:", error);
        }
    },
    byKey: async (key) => {
        const res = await sendRequest(
            `${config.API_URL}/${config.PATHS.USER}/${key}`
        );
        return res;
    },
    insert: (values) => {
        sendRequest(`${config.API_URL}/${config.PATHS.USER}`, "POST", {
            values: values,
        });
    },
    update: (id, values) => {
        sendRequest(`${config.API_URL}/${config.PATHS.USER}/${id}`, "PATCH", {
            values: values,
        });
    },
    remove: (id) => {
        sendRequest(`${config.API_URL}/${config.PATHS.USER}/${id}`, "DELETE");
    },
});
