import { createApp } from "vue";
import App from "./App.vue";
import "devextreme/dist/css/dx.light.css";
import router from "./router";
import config from "devextreme/core/config";
import { locale } from "devextreme/localization";

config({
    // decimalSeparator: ',',
    // thousandsSeparator: '.',
    defaultCurrency: 'EUR',
    serverDecimalSeparator: '.',
    thousandsSeparator: '',
    forceIsoDateParsing:true,
    editorStylingMode: 'filled', // 'outlined' | 'underlined' | 'filled'

    // floatingActionButtonConfig: directions[e.selectedItem]
})

locale("hr-HR")

createApp(App).use(router).mount("#app");
