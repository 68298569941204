<template>
    <div class="table-container">
        <span class="table-name">Početno stanje</span>
        <DxDataGrid
            id="initialStateTable"
            :data-source="initialState"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getInitialStateTableInstance"
            @init-new-row="onInitNewRowInitialState"
            @row-inserting="onRowInsertingInitialState"
            @row-inserted="onRowInsertedInitialState"
            @editing-start="onEditingStartInitialState"
            @row-updating="onRowUpdatingInitialState"
            @row-updated="onRowUpdatedInitialState"
            @row-removing="onRowRemovingInitialState"
            @focused-row-changed="onFocusedRowChangedInitialState"
            @edit-canceled="onEditCanceledInitialState"
            edit-template="initialStateEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="initialStateTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="initialStateAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="initialStateRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o početnom stanju"
                    :toolbar-items="initialStatePopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onPopupShown"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="warehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="warehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                    </DxItem>
                    <DxItem item-type="group" :col-span="2">
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="initialStateItemTable"
                                    :data-source="initialStateItemTableData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="
                                        getInitialStateItemTableInstance
                                    "
                                    @editing-start="
                                        onEditingStartInitialStateItem
                                    "
                                    @row-inserting="
                                        onRowInsertingInitialStateItem
                                    "
                                >
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                initialStateItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="Stanje na skladištu"
                                                    data-type="number"
                                                    :editor-options="
                                                        warehouseStatusOptions
                                                    "
                                                />
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editInitialStateItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteInitialStateItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="warehouseId"
                data-type="number"
                caption="Skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            />
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editInitialState"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteInitialState"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { initialState } from "@/stores/initialStateStore";
import { initialStateItem } from "@/stores/initialStateItemStore";
import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import eventBus from "../../eventBus.js";
import { loggedUser } from "../../user";

export default {
    name: "initialStateTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
    },
    data() {
        return {
            initialStateTableInstance: null,
            initialStateItemTableInstance: null,
            initialState: initialState,
            initialStateItem: initialStateItem,
            priceListItem: priceListItem,
            priceListItemSelectBoxOptions: null,
            warehouse: warehouse,
            warehouseSelectBoxOptions: null,
            warehouseItemStatus: warehouseItemStatus,
            warehouseStatusOptions: null,
            warehouseStatusInstance: null,
            businessYearInstance: null,
            businessYear: businessYear,
            businessYearSelectBoxOptions: null,
            domain: domain,
            user: loggedUser,
            initialStateItemTableData: [],
            rowsToBeDeleted: [],
            editingInitialStateItem: null,
            priceListItemId: null,
            adminId: null,
            businessYearId: null,
            domainId: null,
            selectedRowKey: null,
            qtyInstance: null,
            qty: 1,
            qtyOptions: null,
            priceInstance: null,
            price: 0,
            priceOptions: null,
            priceTotalInstance: null,
            priceTotalOptions: null,
            editingInitialState: null,
            warehouseInstance: null,
            priceListItemInstance: null,
            number: null,
            numberOptions: null,
            numberInstance: null,
            dateSelectOptions: null,
            dateSelectInstance: null,
            selectedDate: null,
            editingInitialStateItemOldData: [],
            newEditingInitialStateItemWarehouseItemStatus: [],
            columnChooserMode: "select",
            initialStateAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addInitialState();
                },
            },
            initialStateItemAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addInitialStateItem();
                },
            },
            initialStateRefreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshInitialStateTableData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
            initialStatePopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.saveNewInitialState,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancelInitialState,
                    },
                },
            ],
            initialStateItemPopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.saveNewInitialStateItem,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancelInitialStateItem,
                    },
                },
            ],
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    created() {
        this.adminId = localStorage.getItem("userId");
        this.businessYearId = localStorage.getItem("businessYearId");
        this.domainId = localStorage.getItem("userDomainId");
        this.subscribeToItemsEvent();
        this.dateSelectOptions = {
            onInitialized: (e) => {
                this.dateSelectInstance = e.component;
            },
            onValueChanged: (e) => {
                this.selectedDate = e.value;
            },
        };
        this.warehouseStatusOptions = {
            disabled: true,
            onInitialized: (e) => {
                this.warehouseStatusInstance = e.component;
            },
        };
        this.numberOptions = {
            disabled: true,
            onInitialized: (e) => {
                this.numberInstance = e.component;
            },
            onValueChanged: () => {
                this.numberInstance.option("disabled", false);
            },
        };
        this.warehouseSelectBoxOptions = {
            dataSource: this.warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                this.warehouseInstance = e.component;
                this.warehouseInstance.focus();
            },
            onSelectionChanged: async (e) => {
                if (!this.warehouseInstance.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: Number(this.businessYearId),
                    };
                    const res = await this.initialState.load(loadOptions);
                    if (res.length > 0) {
                        this.numberInstance.option("value", res[0].number + 1);
                        this.number = res[0].number + 1;
                    } else {
                        this.numberInstance.option("value", 1);
                        this.number = 1;
                    }
                }
            },
        };
        this.priceListItemSelectBoxOptions = {
            dataSource: this.priceListItem,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                this.priceListItemInstance = e.component;
            },
            onValueChanged: async (e) => {
                // console.log(e);
                const selectedWarehouseId =
                    this.warehouseInstance.option("value");
                // console.log(this.warehouseInstance.option("value"));
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await this.warehouseItemStatus.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        this.warehouseStatusInstance.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        this.warehouseStatusInstance.option("value", 0);
                    }
                } else {
                    this.warehouseStatusInstance.option("value", 0);
                }
                const res = await this.priceListItem.byKey(e.value);
                if (res.wholesalePrice !== null) {
                    this.priceInstance.option("value", res.wholesalePrice);
                } else {
                    this.priceInstance.option("value", 1);
                }
                this.priceListItemId = e.value;
            },
        };
        this.businessYearSelectBoxOptions = {
            dataSource: this.businessYear,
            valueExpr: "id",
            readOnly: true,
        };
        this.qtyOptions = {
            value: this.qty,
            onInitialized: (e) => {
                this.qtyInstance = e.component;
                this.qtyInstance.option("value", this.qty);
            },
            onValueChanged: (e) => {
                this.qty = e.value;
                this.qtyInstance.option("value", e.value);
                this.updateTotalValue();
            },
        };
        this.priceOptions = {
            value: this.price,
            onInitialized: (e) => {
                this.priceInstance = e.component;
            },
            onValueChanged: (e) => {
                this.price = e.value;
                this.priceInstance.option("value", e.value);
                this.updateTotalValue();
            },
        };
        this.priceTotalOptions = {
            value: this.priceTotal,
            readOnly: true,
            onInitialized: (e) => {
                this.priceTotalInstance = e.component;
            },
        };
    },
    methods: {
        getInitialStateTableInstance(e) {
            this.initialStateTableInstance = e.component;
        },
        getInitialStateItemTableInstance(e) {
            this.initialStateItemTableInstance = e.component;
        },
        onInitNewRowInitialState(e) {
            const now = new Date();
            e.data = {
                businessYearId: Number(this.businessYearId),
            };
            this.priceListItemId = null;
            this.initialStateTableInstance.option("focusedRowIndex", -1);
            // await this.initialStateItem.load();
            this.initialStateItemTableData = [];
            setTimeout(() => {
                this.dateSelectInstance.option("value", now.toISOString());
                // this.selectedDate = now.toISOString();
            }, 100);
        },
        onRowInsertingInitialState(e) {
            const now = new Date();
            e.data = {
                ...e.data,
                number: this.number,
                date: this.selectedDate,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(this.domainId),
                priceTotal: 0,
            };
        },
        async onRowInsertedInitialState(e) {
            let initialStatePriceTotal = 0;
            const now = new Date();
            for (let i = 0; i < this.initialStateItemTableData.length; i++) {
                const item = this.initialStateItemTableData[i];
                delete item.__KEY__;
                item.initialStateId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                this.initialStateItem.insert(item);
                await this.updateWarehouseItemStatusOnNewInitialState(
                    item,
                    e.data.warehouseId
                );
                initialStatePriceTotal += item.priceTotal;
            }
            this.initialState.update(e.key, {
                priceTotal: initialStatePriceTotal,
            });
            this.initialStateItemTableData = [];
            this.qty = 1;
            this.initialStateTableInstance.refresh();
        },
        onEditingStartInitialState(e) {
            this.editingInitialState = e.data;
            const now = new Date();
            this.initialStateItem.load();
            setTimeout(() => {
                this.initialStateTableInstance.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                this.warehouseInstance.option("disabled", true);
                this.dateSelectInstance.option("value", e.data.date);
            }, 100);
            this.priceListItemId = null;
        },
        onEditingStartInitialStateItem(e) {
            this.editingInitialStateItem = e.data;
            this.qty = e.data.qty;
            const oldData = {
                ...this.editingInitialStateItem,
            };
            setTimeout(() => {
                this.priceListItemInstance.option("disabled", true);
            }, 100);
            this.editingInitialStateItemOldData.push(oldData);
            this.price = e.data.price;
            this.priceListItemId = null;
        },
        onEditCanceledInitialState() {
            this.rowsToBeDeleted = [];
            this.newEditingInitialStateItemWarehouseItemStatus = [];
            this.editingInitialStateItemOldData = [];
            this.initialStateItemTableData = [];
        },
        async onRowUpdatingInitialState(e) {
            if (this.selectedDate !== e.oldData.date) {
                e.newData.date = this.selectedDate;
            }
            let initialStatePriceTotal = 0;
            const now = new Date();
            this.rowsToBeDeleted.forEach(async (item) => {
                this.initialStateItem.remove(item.id);
                const loadOptions = {
                    priceListItemId: item.priceListItemId,
                    warehouseId: e.oldData.warehouseId,
                };
                const res = await this.warehouseItemStatus.load(loadOptions);
                // console.log(item);
                const newQty =
                    item.qty > 0
                        ? res[0].qty - Math.abs(item.qty)
                        : res[0].qty + Math.abs(item.qty);
                const updateData = {
                    qty: newQty,
                };
                await this.warehouseItemStatus.update(res[0].id, updateData);
            });
            await this.updateWarehouseItemStatusOnUpdatedInitialState(
                e.oldData.warehouseId
            );
            this.rowsToBeDeleted = [];
            this.initialStateItemTableData.forEach((item) => {
                if (item.id) {
                    this.initialStateItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                item.initialStateId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                this.initialStateItem.insert(item);
                initialStatePriceTotal += item.priceTotal;
            });
            this.initialState.update(e.key, {
                priceTotal: initialStatePriceTotal,
            });
            this.initialStateTableInstance.refresh();
            this.initialStateItemTableData = [];
            this.qty = 1;
        },
        onRowUpdatedInitialState() {
            eventBus.emit("newInitialStateItemAdded");
        },
        saveNewInitialState() {
            this.initialStateTableInstance.saveEditData();
        },
        saveNewInitialStateItem() {
            const now = new Date();
            if (this.editingInitialStateItem !== null) {
                this.editingInitialStateItem.qty = this.qty;
                this.editingInitialStateItem.price = this.price;
                this.editingInitialStateItem.priceTotal = this.priceTotal;
                this.editingInitialStateItem.modifiedDate = now.toISOString();
                this.editingInitialStateItem.modifiedById = Number(
                    this.adminId
                );
                this.newEditingInitialStateItemWarehouseItemStatus.push(
                    this.editingInitialStateItem
                );
                this.initialStateItemTableData.find((el) => {
                    if (
                        this.editingInitialStateItem.__KEY__ &&
                        el.__KEY__ === this.editingInitialStateItem.__KEY__
                    ) {
                        el = this.editingInitialStateItem;
                    } else if (el.id === this.editingInitialStateItem.id) {
                        el = this.editingInitialStateItem;
                    }
                });
                this.initialStateItemTableInstance.saveEditData();
                this.editingInitialStateItem = null;
                this.qty = 1;
            } else {
                this.initialStateItemTableInstance.saveEditData();
                this.editingInitialStateItem = null;
                this.qty = 1;
            }
        },
        onRowInsertingInitialStateItem(e) {
            e.data = {
                ...e.data,
                qty: this.qty,
                price: this.price,
                priceTotal: this.priceTotal,
                priceListItemId: this.priceListItemId,
            };
            this.qty = 1;
        },
        addInitialState() {
            this.initialStateTableInstance.addRow();
        },
        cancelInitialState() {
            this.initialStateTableInstance.cancelEditData();
        },
        editInitialState(e) {
            this.initialStateTableInstance = e.component;
            this.initialStateTableInstance.editRow(e.row.rowIndex);
        },
        deleteInitialState(e) {
            this.initialStateTableInstance = e.component;
            this.initialStateTableInstance.deleteRow(e.row.rowIndex);
        },
        onRowRemovingInitialState(e) {
            this.initialStateItemTableData.forEach(async (el) => {
                this.initialStateItem.remove(el.id);
                await this.updateWarehouseItemStatusOnRemovedInitialState(
                    el,
                    e.data.warehouseId
                );
            });
        },
        addInitialStateItem() {
            this.initialStateItemTableInstance.addRow();
        },
        cancelInitialStateItem() {
            this.editingInitialStateItemOldData.pop();
            this.initialStateItemTableInstance.cancelEditData();
            this.editingInitialStateItem = null;
            this.qty = 1;
        },
        editInitialStateItem(e) {
            this.initialStateItemTableInstance = e.component;
            this.initialStateItemTableInstance.editRow(e.row.rowIndex);
        },
        deleteInitialStateItem(e) {
            this.initialStateItemTableInstance = e.component;
            this.initialStateItemTableData.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                this.rowsToBeDeleted.push(e.row.data);
            }
            this.initialStateItemTableInstance.refresh();
        },
        async updateWarehouseItemStatusOnUpdatedInitialState(warehouseId) {
            if (this.newEditingInitialStateItemWarehouseItemStatus.length > 0) {
                for (
                    let i = 0;
                    i <
                    this.newEditingInitialStateItemWarehouseItemStatus.length;
                    i++
                ) {
                    const el =
                        this.newEditingInitialStateItemWarehouseItemStatus[i];
                    const element = this.editingInitialStateItemOldData.find(
                        (x) => {
                            return x.priceListItemId === el.priceListItemId;
                        }
                    );
                    const loadOptions = {
                        priceListItemId: el.priceListItemId,
                        warehouseId: warehouseId,
                    };
                    const res = await this.warehouseItemStatus.load(
                        loadOptions
                    );
                    const newQty = el.qty - element.qty;
                    const updateData = {
                        qty:
                            newQty > 0
                                ? res[0].qty + Math.abs(newQty)
                                : res[0].qty - Math.abs(newQty),
                    };
                    await this.warehouseItemStatus.update(
                        res[0].id,
                        updateData
                    );
                }
            }
            for (let i = 0; i < this.initialStateItemTableData.length; i++) {
                const element = this.initialStateItemTableData[i];
                const loadOptions = {
                    priceListItemId: element.priceListItemId,
                    warehouseId: warehouseId,
                };
                const res = await this.warehouseItemStatus.load(loadOptions);
                if (
                    !this.newEditingInitialStateItemWarehouseItemStatus.includes(
                        element
                    ) &&
                    res.length === 0
                ) {
                    const newData = {
                        priceListItemId: element.priceListItemId,
                        priceLatest: element.price,
                        priceAverage: element.price,
                        qty: element.qty,
                        warehouseId: warehouseId,
                    };
                    this.warehouseItemStatus.insert(newData);
                } else if (
                    !this.newEditingInitialStateItemWarehouseItemStatus.includes(
                        element
                    ) &&
                    res.length === 1 &&
                    element.__KEY__
                ) {
                    const updateData = {
                        qty:
                            element.qty > 0
                                ? res[0].qty + Math.abs(element.qty)
                                : res[0].qty - Math.abs(element.qty),
                    };
                    this.warehouseItemStatus.update(res[0].id, updateData);
                }
            }
            this.newEditingInitialStateItemWarehouseItemStatus = [];
            this.editingInitialStateItemOldData = [];
        },
        async updateWarehouseItemStatusOnRemovedInitialState(
            item,
            warehouseId
        ) {
            const loadOptions = {
                priceListItemId: item.priceListItemId,
                warehouseId: warehouseId,
            };
            const res = await this.warehouseItemStatus.load(loadOptions);
            const updateData = {
                qty:
                    item.qty > 0
                        ? res[0].qty - Math.abs(item.qty)
                        : res[0].qty + Math.abs(item.qty),
            };
            await this.warehouseItemStatus.update(res[0].id, updateData);
        },
        async updateWarehouseItemStatusOnNewInitialState(item, warehouseId) {
            const loadOptions = {
                priceListItemId: item.priceListItemId,
                warehouseId: warehouseId,
            };
            const res = await this.warehouseItemStatus.load(loadOptions);
            if (res.length > 0) {
                const updateData = {
                    qty:
                        item.qty > 0
                            ? res[0].qty + Math.abs(item.qty)
                            : res[0].qty - Math.abs(item.qty),
                    priceLatest: item.price,
                };
                await this.warehouseItemStatus.update(res[0].id, updateData);
            } else {
                const newData = {
                    priceListItemId: item.priceListItemId,
                    priceLatest: item.price,
                    priceAverage: item.price,
                    qty: item.qty,
                    warehouseId: warehouseId,
                };
                this.warehouseItemStatus.insert(newData);
            }
        },
        updateTotalValue() {
            this.priceTotalInstance.option("value", this.qty * this.price);
            this.priceTotal = this.qty * this.price;
        },
        onFocusedRowChangedInitialState(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                this.selectedRowKey = focusedRowKey;
                if (this.selectedRowKey !== null) {
                    eventBus.emit(
                        "newFocusedRowInitialState",
                        this.selectedRowKey
                    );
                }
            } else {
                eventBus.emit("newFocusedRowInitialState", null);
            }
        },
        onPopupShown() {
            this.warehouseInstance.focus();
        },
        openColumnChooser() {
            this.initialStateTableInstance.showColumnChooser();
        },
        subscribeToItemsEvent() {
            eventBus.on("initialStateItems", (data) => {
                this.initialStateItemTableData = data;
            });
        },
        refreshInitialStateTableData() {
            const message = "Podaci osvježeni!";
            this.initialStateTableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateTable {
    max-width: 100%;
}
#initialStateItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
