import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const domain = new CustomStore({
    key: "id",
    load: () => sendRequest(`${config.API_URL}/${config.PATHS.DOMAIN}`),
    byKey: (key) => {
        sendRequest(`${config.API_URL}/${config.PATHS.DOMAIN}/${key}`);
    },
    insert: (values) => {
        sendRequest(`${config.API_URL}/${config.PATHS.DOMAIN}`, "POST", {
            values: values,
        });
    },
    update: (id, values) => {
        sendRequest(`${config.API_URL}/${config.PATHS.DOMAIN}/${id}`, "PATCH", {
            values: values,
        });
    },
    remove: (id) => {
        sendRequest(`${config.API_URL}/${config.PATHS.DOMAIN}/${id}`, "DELETE");
    },
});
