<template>
    <div class="table-container">
        <span class="table-name">Međuskladišnica</span>
        <DxDataGrid
            id="intermediateWarehouseTable"
            :data-source="intermediateWarehouse"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getIntermediateWarehouseTableInstance"
            @init-new-row="onInitNewRowIntermediateWarehouse"
            @row-inserting="onRowInsertingIntermediateWarehouse"
            @row-inserted="onRowInsertedIntermediateWarehouse"
            @editing-start="onEditingStartIntermediateWarehouse"
            @row-updating="onRowUpdatingIntermediateWarehouse"
            @row-updated="onRowUpdatedIntermediateWarehouse"
            @row-removing="onRowRemovingIntermediateWarehouse"
            @focused-row-changed="onFocusedRowChangedIntermediateWarehouse"
            @edit-canceled="onEditCanceledIntermediateWarehouse"
            edit-template="intermediateWarehouseEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="intermediateWarehouseTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="intermediateWarehouseAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="intermediateWarehouseRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o međuskladišnici"
                    :toolbar-items="intermediateWarehousePopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onPopupShown"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>
                        <DxItem
                            data-field="fromWarehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="fromWarehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="toWarehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="toWarehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                    </DxItem>
                    <DxItem item-type="group" :col-span="2">
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="intermediateWarehouseItemTable"
                                    :data-source="intermediateWarehouseItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="
                                        getIntermediateWarehouseItemTableInstance
                                    "
                                    @editing-start="
                                        onEditingStartIntermediateWarehouseItem
                                    "
                                    @row-inserting="
                                        onRowInsertingIntermediateWarehouseItem
                                    "
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="500"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                intermediateWarehouseItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                    />
                                                </DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="
                                                editIntermediateWarehouseItem
                                            "
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="
                                                deleteIntermediateWarehouseItem
                                            "
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="fromWarehouseId"
                data-type="number"
                caption="Iz skladišta"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="toWarehouseId"
                data-type="number"
                caption="U skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            />
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editIntermediateWarehouse"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteIntermediateWarehouse"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { intermediateWarehouse } from "@/stores/intermediateWarehouseStore.js";
import { intermediateWarehouseItem } from "@/stores/intermediateWarehouseItemStore.js";
import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import eventBus from "../../eventBus.js";
import { loggedUser } from "../../user";

export default {
    name: "intermediateWarehouseTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
    },
    data() {
        return {
            intermediateWarehouseTableInstance: null,
            intermediateWarehouseItemTableInstance: null,
            intermediateWarehouse: intermediateWarehouse,
            intermediateWarehouseItem: intermediateWarehouseItem,
            priceListItem: priceListItem,
            priceListItemSelectBoxOptions: null,
            priceListItemInstance: null,
            warehouse: warehouse,
            fromWarehouseSelectBoxOptions: null,
            toWarehouseSelectBoxOptions: null,
            fromWarehouseInstance: null,
            toWarehouseInstance: null,
            warehouseItemStatus: warehouseItemStatus,
            businessYearInstance: null,
            businessYear: businessYear,
            businessYearSelectBoxOptions: null,
            domain: domain,
            user: loggedUser,
            intermediateWarehouseItemData: [],
            rowsToBeDeleted: [],
            editingIntermediateWarehouseItem: null,
            editingIntermediateWarehouseItemOldData: [],
            newEditingIntermediateWarehouseWarehouseItemStatus: [],
            priceListItemId: null,
            adminId: null,
            businessYearId: null,
            domainId: null,
            selectedRowKey: null,
            qtyInstance: null,
            qty: 1,
            qtyOptions: null,
            priceInstance: null,
            price: 0,
            priceOptions: null,
            priceTotalInstance: null,
            priceTotalOptions: null,
            number: null,
            numberInstance: null,
            numberOptions: null,
            dateSelectOptions: null,
            dateSelectInstance: null,
            selectedDate: null,
            columnChooserMode: "select",
            intermediateWarehouseAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addIntermediateWarehouse();
                },
            },
            intermediateWarehouseItemAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addIntermediateWarehouseItem();
                },
            },
            intermediateWarehouseRefreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshIntermediateWarehouseTableData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
            intermediateWarehousePopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.saveNewIntermediateWarehouse,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancelIntermediateWarehouse,
                    },
                },
            ],
            intermediateWarehouseItemPopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.saveNewIntermediateWarehouseItem,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancelIntermediateWarehouseItem,
                    },
                },
            ],
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    created() {
        this.adminId = localStorage.getItem("userId");
        this.businessYearId = localStorage.getItem("businessYearId");
        this.domainId = localStorage.getItem("userDomainId");
        this.subscribeToItemsEvent();
        this.dateSelectOptions = {
            onInitialized: (e) => {
                this.dateSelectInstance = e.component;
            },
            onValueChanged: (e) => {
                this.selectedDate = e.value;
            },
        };
        this.numberOptions = {
            disabled: true,
            onInitialized: (e) => {
                this.numberInstance = e.component;
            },
            onValueChanged: () => {
                this.numberInstance.option("disabled", false);
            },
        };
        this.fromWarehouseSelectBoxOptions = {
            dataSource: this.warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                this.fromWarehouseInstance = e.component;
            },
            onSelectionChanged: async (e) => {
                if (
                    this.toWarehouseInstance.option("selectedItem") &&
                    this.fromWarehouseInstance.option("selectedItem")
                ) {
                    if (
                        this.fromWarehouseInstance.option("selectedItem").id ===
                        this.toWarehouseInstance.option("selectedItem").id
                    ) {
                        this.toWarehouseInstance.reset();
                    }
                }
                if (
                    !this.fromWarehouseInstance.option("disabled") &&
                    e.selectedItem !== null
                ) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: Number(this.businessYearId),
                    };
                    const res = await this.intermediateWarehouse.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        this.numberInstance.option("value", res[0].number + 1);
                        this.number = res[0].number + 1;
                    } else {
                        this.numberInstance.option("value", 1);
                        this.number = 1;
                    }
                }
            },
        };
        this.toWarehouseSelectBoxOptions = {
            dataSource: this.warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                this.toWarehouseInstance = e.component;
            },
            onSelectionChanged: () => {
                if (
                    this.fromWarehouseInstance.option("selectedItem") &&
                    this.toWarehouseInstance.option("selectedItem")
                ) {
                    if (
                        this.toWarehouseInstance.option("selectedItem").id ===
                        this.fromWarehouseInstance.option("selectedItem").id
                    ) {
                        this.fromWarehouseInstance.reset();
                    }
                }
            },
        };
        this.priceListItemSelectBoxOptions = {
            dataSource: this.priceListItem,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                this.priceListItemInstance = e.component;
            },
            onValueChanged: async (e) => {
                const res = await this.priceListItem.byKey(e.value);
                if (res.wholesalePrice !== null) {
                    this.priceInstance.option("value", res.wholesalePrice);
                } else {
                    this.priceInstance.option("value", 1);
                }
                this.priceListItemId = e.value;
            },
        };
        this.businessYearSelectBoxOptions = {
            dataSource: this.businessYear,
            valueExpr: "id",
            readOnly: true,
        };
        this.qtyOptions = {
            value: this.qty,
            onInitialized: (e) => {
                this.qtyInstance = e.component;
                this.qtyInstance.option("value", this.qty);
            },
            onValueChanged: (e) => {
                this.qty = e.value;
                this.qtyInstance.option("value", e.value);
                this.updateTotalValue();
            },
        };
        this.priceOptions = {
            value: this.price,
            onInitialized: (e) => {
                this.priceInstance = e.component;
            },
            onValueChanged: (e) => {
                this.price = e.value;
                this.priceInstance.option("value", e.value);
                this.updateTotalValue();
            },
        };
        this.priceTotalOptions = {
            value: this.priceTotal,
            readOnly: true,
            onInitialized: (e) => {
                this.priceTotalInstance = e.component;
            },
        };
    },

    methods: {
        getIntermediateWarehouseTableInstance(e) {
            this.intermediateWarehouseTableInstance = e.component;
        },
        getIntermediateWarehouseItemTableInstance(e) {
            this.intermediateWarehouseItemTableInstance = e.component;
        },
        onInitNewRowIntermediateWarehouse(e) {
            const now = new Date();
            e.data = {
                businessYearId: Number(this.businessYearId),
            };
            this.priceListItemId = null;
            this.intermediateWarehouseTableInstance.option(
                "focusedRowIndex",
                -1
            );
            // await this.intermediateWarehouseItem.load();
            this.intermediateWarehouseItemData = [];
            setTimeout(() => {
                this.dateSelectInstance.option("value", now.toISOString());
                // this.selectedDate = now.toISOString();
            }, 100);
        },
        onRowInsertingIntermediateWarehouse(e) {
            const now = new Date();
            e.data = {
                ...e.data,
                date: this.selectedDate,
                number: this.number,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(this.domainId),
            };
        },
        async onRowInsertedIntermediateWarehouse(e) {
            let intermediateWarehousePriceTotal = 0;
            const now = new Date();
            for (
                let i = 0;
                i < this.intermediateWarehouseItemData.length;
                i++
            ) {
                const item = this.intermediateWarehouseItemData[i];
                delete item.__KEY__;
                item.intermediateWarehouseId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                this.intermediateWarehouseItem.insert(item);
                await this.updateWarehouseItemStatusOnNewIntermediateWarehouse(
                    item,
                    e.data.fromWarehouseId,
                    e.data.toWarehouseId
                );
                intermediateWarehousePriceTotal += item.priceTotal;
            }
            this.intermediateWarehouse.update(e.key, {
                priceTotal: intermediateWarehousePriceTotal,
            });
            this.intermediateWarehouseItemData = [];
            this.qty = 1;
            this.intermediateWarehouseTableInstance.refresh();
        },
        onEditingStartIntermediateWarehouse(e) {
            // console.log(e);
            this.editingIntermediateWarehouse = e.data;
            const now = new Date();
            this.intermediateWarehouseItem.load();
            setTimeout(() => {
                this.intermediateWarehouseTableInstance.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                this.fromWarehouseInstance.option("disabled", true);
                this.toWarehouseInstance.option("disabled", true);
                this.dateSelectInstance.option("value", e.data.date);
                // this.selectedDate = e.data.date;
            }, 100);
            this.priceListItemId = null;
        },
        onEditingStartIntermediateWarehouseItem(e) {
            this.editingIntermediateWarehouseItem = e.data;
            this.qty = e.data.qty;
            const oldData = {
                ...this.editingIntermediateWarehouseItem,
            };
            setTimeout(() => {
                this.priceListItemInstance.option("disabled", true);
            }, 100);
            this.editingIntermediateWarehouseItemOldData.push(oldData);
            this.price = e.data.price;
            this.priceListItemId = null;
        },
        onEditCanceledIntermediateWarehouse() {
            this.rowsToBeDeleted = [];
            this.intermediateWarehouseItemData = [];
        },
        async onRowUpdatingIntermediateWarehouse(e) {
            // console.log(this.selectedDate);
            if (this.selectedDate !== e.oldData.date) {
                e.newData.date = this.selectedDate;
            }
            // console.log(e);
            let intermediateWarehousePriceTotal = 0;
            const now = new Date();
            this.rowsToBeDeleted.forEach((id) => {
                this.intermediateWarehouseItem.remove(id);
            });
            this.rowsToBeDeleted = [];
            await this.updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
                e.oldData.fromWarehouseId,
                e.oldData.toWarehouseId
            );
            this.intermediateWarehouseItemData.forEach((item) => {
                if (item.id) {
                    this.intermediateWarehouseItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                item.intermediateWarehouseId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                this.intermediateWarehouseItem.insert(item);
                intermediateWarehousePriceTotal += item.priceTotal;
            });
            this.intermediateWarehouse.update(e.key, {
                priceTotal: intermediateWarehousePriceTotal,
            });
            this.intermediateWarehouseTableInstance.refresh();
            this.intermediateWarehouseItemData = [];
            this.qty = 1;
        },
        onRowUpdatedIntermediateWarehouse() {
            eventBus.emit("newIntermediateWarehouseItemAdded");
        },
        saveNewIntermediateWarehouse() {
            this.intermediateWarehouseTableInstance.saveEditData();
        },
        saveNewIntermediateWarehouseItem() {
            const now = new Date();
            if (this.editingIntermediateWarehouseItem !== null) {
                this.editingIntermediateWarehouseItem.qty = this.qty;
                this.editingIntermediateWarehouseItem.price = this.price;
                this.editingIntermediateWarehouseItem.priceTotal =
                    this.priceTotal;
                this.editingIntermediateWarehouseItem.modifiedDate =
                    now.toISOString();
                this.editingIntermediateWarehouseItem.modifiedById = Number(
                    this.adminId
                );
                this.newEditingIntermediateWarehouseWarehouseItemStatus.push(
                    this.editingIntermediateWarehouseItem
                );
                this.intermediateWarehouseItemData.find((el) => {
                    if (
                        this.editingIntermediateWarehouseItem.__KEY__ &&
                        el.__KEY__ ==
                            this.editingIntermediateWarehouseItem.__KEY__
                    ) {
                        el = this.editingIntermediateWarehouseItem;
                    } else if (
                        el.id === this.editingIntermediateWarehouseItem.id
                    ) {
                        el = this.editingIntermediateWarehouseItem;
                    }
                });
                this.intermediateWarehouseItemTableInstance.saveEditData();
                this.editingIntermediateWarehouseItem = null;
                this.qty = 1;
            } else {
                this.intermediateWarehouseItemTableInstance.saveEditData();
                this.editingIntermediateWarehouseItem = null;
                this.qty = 1;
            }
        },
        onRowInsertingIntermediateWarehouseItem(e) {
            e.data = {
                ...e.data,
                qty: this.qty,
                price: this.price,
                priceTotal: this.priceTotal,
                priceListItemId: this.priceListItemId,
            };
            this.qty = 1;
        },
        addIntermediateWarehouse() {
            this.intermediateWarehouseTableInstance.addRow();
        },
        cancelIntermediateWarehouse() {
            this.intermediateWarehouseTableInstance.cancelEditData();
        },
        editIntermediateWarehouse(e) {
            this.intermediateWarehouseTableInstance = e.component;
            this.intermediateWarehouseTableInstance.editRow(e.row.rowIndex);
        },
        deleteIntermediateWarehouse(e) {
            this.intermediateWarehouseTableInstance = e.component;
            this.intermediateWarehouseTableInstance.deleteRow(e.row.rowIndex);
        },
        onRowRemovingIntermediateWarehouse(e) {
            this.intermediateWarehouseItemData.forEach(async (el) => {
                this.intermediateWarehouseItem.remove(el.id);
                await this.updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
                    el,
                    e.data.fromWarehouseId,
                    e.data.toWarehouseId
                );
            });
        },
        addIntermediateWarehouseItem() {
            this.intermediateWarehouseItemTableInstance.addRow();
        },
        cancelIntermediateWarehouseItem() {
            this.editingIntermediateWarehouseItemOldData.pop();
            this.intermediateWarehouseItemTableInstance.cancelEditData();
            this.editingIntermediateWarehouseItem = null;
            this.qty = 1;
        },
        editIntermediateWarehouseItem(e) {
            this.intermediateWarehouseItemTableInstance = e.component;
            this.intermediateWarehouseItemTableInstance.editRow(e.row.rowIndex);
        },
        deleteIntermediateWarehouseItem(e) {
            this.intermediateWarehouseItemTableInstance = e.component;
            this.intermediateWarehouseItemData.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                this.rowsToBeDeleted.push(e.row.data.id);
            }
            this.intermediateWarehouseItemTableInstance.refresh();
        },
        async updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
            item,
            fromWarehouseId,
            toWarehouseId
        ) {
            let loadOptions = {
                priceListItemId: item.priceListItemId,
                warehouseId: toWarehouseId,
            };
            let res = await this.warehouseItemStatus.load(loadOptions);
            let updateData = {
                qty: res[0].qty - item.qty,
            };
            this.warehouseItemStatus.update(res[0].id, updateData);
            loadOptions.warehouseId = fromWarehouseId;
            res = await this.warehouseItemStatus.load(loadOptions);
            updateData.qty = res[0].qty + item.qty;
            this.warehouseItemStatus.update(res[0].id, updateData);
        },
        async updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
            fromWarehouseId,
            toWarehouseId
        ) {
            if (
                this.newEditingIntermediateWarehouseWarehouseItemStatus.length >
                0
            ) {
                for (
                    let i = 0;
                    i <
                    this.newEditingIntermediateWarehouseWarehouseItemStatus
                        .length;
                    i++
                ) {
                    const el =
                        this.newEditingIntermediateWarehouseWarehouseItemStatus[
                            i
                        ];
                    const element =
                        this.editingIntermediateWarehouseItemOldData.find(
                            (x) => {
                                return x.priceListItemId === el.priceListItemId;
                            }
                        );
                    let loadOptions = {
                        priceListItemId: el.priceListItemId,
                        warehouseId: toWarehouseId,
                    };
                    let res = await this.warehouseItemStatus.load(loadOptions);
                    let newQty = el.qty - element.qty;
                    const updateData = {};
                    if (newQty < 0) {
                        updateData.qty = res[0].qty - Math.abs(newQty);
                    } else {
                        updateData.qty = res[0].qty + Math.abs(newQty);
                    }
                    await this.warehouseItemStatus.update(
                        res[0].id,
                        updateData
                    );
                    loadOptions.warehouseId = fromWarehouseId;
                    res = await this.warehouseItemStatus.load(loadOptions);
                    if (newQty < 0) {
                        updateData.qty = res[0].qty + Math.abs(newQty);
                    } else {
                        updateData.qty = res[0].qty - Math.abs(newQty);
                    }
                    await this.warehouseItemStatus.update(
                        res[0].id,
                        updateData
                    );
                }
            }
            for (
                let i = 0;
                i < this.intermediateWarehouseItemData.length;
                i++
            ) {
                const element = this.intermediateWarehouseItemData[i];
                let loadOptions = {
                    priceListItemId: element.priceListItemId,
                    warehouseId: fromWarehouseId,
                };
                let res = await this.warehouseItemStatus.load(loadOptions);
                if (
                    !this.newEditingIntermediateWarehouseWarehouseItemStatus.includes(
                        element
                    ) &&
                    res.length === 0
                ) {
                    const newData = {
                        priceListItemId: element.priceListItemId,
                        priceLatest: element.price,
                        priceAverage: element.price,
                        qty: -element.qty,
                        warehouseId: fromWarehouseId,
                    };
                    this.warehouseItemStatus.insert(newData);
                    loadOptions.warehouseId = toWarehouseId;
                    res = await this.warehouseItemStatus.load(loadOptions);
                    if (res.length > 0) {
                        const updateData = {
                            qty: res[0].qty + element.qty,
                        };
                        await this.warehouseItemStatus.update(
                            res[0].id,
                            updateData
                        );
                    } else {
                        const newData = {
                            priceListItemId: element.priceListItemId,
                            priceLatest: element.price,
                            priceAverage: element.price,
                            qty: element.qty,
                            warehouseId: toWarehouseId,
                        };
                        this.warehouseItemStatus.insert(newData);
                    }
                } else if (
                    !this.newEditingIntermediateWarehouseWarehouseItemStatus.includes(
                        element
                    ) &&
                    res.length === 1 &&
                    element.__KEY__
                ) {
                    let updateData = {
                        qty: res[0].qty - element.qty,
                    };
                    this.warehouseItemStatus.update(res[0].id, updateData);
                    loadOptions.warehouseId = toWarehouseId;
                    res = await this.warehouseItemStatus.load(loadOptions);
                    if (res.length > 0) {
                        updateData = {
                            qty: res[0].qty + element.qty,
                        };
                        this.warehouseItemStatus.update(res[0].id, updateData);
                    } else {
                        const newData = {
                            priceListItemId: element.priceListItemId,
                            priceLatest: element.price,
                            priceAverage: element.price,
                            qty: element.qty,
                            warehouseId: toWarehouseId,
                        };
                        this.warehouseItemStatus.insert(newData);
                    }
                }
            }
            this.newEditingIntermediateWarehouseWarehouseItemStatus = [];
            this.editingIntermediateWarehouseItemOldData = [];
        },
        async updateWarehouseItemStatusOnNewIntermediateWarehouse(
            item,
            fromWarehouseId,
            toWarehouseId
        ) {
            let loadOptions = {
                priceListItemId: item.priceListItemId,
                warehouseId: fromWarehouseId,
            };
            let res = await this.warehouseItemStatus.load(loadOptions);
            if (res.length > 0) {
                const updateData = {
                    qty: res[0].qty - item.qty,
                };
                this.warehouseItemStatus.update(res[0].id, updateData);
            } else {
                const newData = {
                    priceListItemId: item.priceListItemId,
                    priceAverage: item.price,
                    priceLatest: item.price,
                    qty: -item.qty,
                    warehouseId: fromWarehouseId,
                };
                this.warehouseItemStatus.insert(newData);
            }
            loadOptions.warehouseId = toWarehouseId;
            res = await this.warehouseItemStatus.load(loadOptions);
            if (res.length > 0) {
                const updateData = {
                    qty: res[0].qty + item.qty,
                };
                this.warehouseItemStatus.update(res[0].id, updateData);
            } else {
                const newData = {
                    priceListItemId: item.priceListItemId,
                    priceLatest: item.price,
                    priceAverage: item.price,
                    qty: item.qty,
                    warehouseId: toWarehouseId,
                };
                this.warehouseItemStatus.insert(newData);
            }
        },
        updateTotalValue() {
            this.priceTotalInstance.option("value", this.qty * this.price);
            this.priceTotal = this.qty * this.price;
        },
        onPopupShown() {
            this.fromWarehouseInstance.focus();
        },
        onFocusedRowChangedIntermediateWarehouse(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                this.selectedRowKey = focusedRowKey;
                if (this.selectedRowKey !== null) {
                    eventBus.emit(
                        "newFocusedRowIntermediateWarehouse",
                        this.selectedRowKey
                    );
                }
            } else {
                eventBus.emit("newFocusedRowIntermediateWarehouse", null);
            }
        },
        openColumnChooser() {
            this.intermediateWarehouseTableInstance.showColumnChooser();
        },
        subscribeToItemsEvent() {
            eventBus.on("intermediateWarehouseItems", (data) => {
                this.intermediateWarehouseItemData = data;
            });
        },
        refreshIntermediateWarehouseTableData() {
            const message = "Podaci osvježeni!";
            this.intermediateWarehouseTableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#intermediateWarehouseTable {
    max-width: 100%;
}
#intermediateWarehouseItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
