export const sendRequest = async (url, method = "GET", data = {}) => {
    const otherMethods = ["POST", "PATCH"];
    const request = {};

    request.method = method;
    if (otherMethods.includes(method)) {
        request.headers = {
            "Content-Type": "application/json",
        };
        request.body = JSON.stringify(data.values);
    }

    const response = await fetch(url, request);
    let result;
    if (response.status === 200) {
        result = await response.json();
    }
    if (!response.ok) {
        result = await response.json();
        throw result.error.message;
    }
    return result;
};
