import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

// EventBus
let filterId = null;
eventBus.on("newFocusedRowWarehouse", (id) => {
    filterId = id;
});

export const warehouseItemStatus = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        // console.log(loadOptions);
        try {
            const filterCondition = {};
            if (loadOptions.priceListItemId || loadOptions.warehouseId) {
                filterCondition.where = {
                    and: [
                        { priceListItemId: loadOptions.priceListItemId },
                        { warehouseId: loadOptions.warehouseId },
                    ],
                };
            } else if (filterId) {
                filterCondition.where = {
                    warehouseId: filterId,
                };
            } else if (!filterId) {
                return [];
            }
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.WAREHOUSE_ITEM_STATUS
                }?filter=${JSON.stringify(filterCondition)}`
            );
            // console.log(res);
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            return -1;
        }
    },
    byKey: async (key) => {
        const res = await sendRequest(
            `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${key}`
        );
        return res;
    },
    update: (id, values) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${id}`,
            "PATCH",
            {
                values: values,
            }
        );
    },
    insert: (values) => {
        sendRequest(
            `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}`,
            "POST",
            {
                values: values,
            }
        );
    },
    remove: (id) =>
        sendRequest(
            `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${id}`,
            "DELETE"
        ),
});
